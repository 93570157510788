footer {
    padding: 18px;
    background-color: $color-lighter-grey;
    font-size: 13px;
    line-height: 24px;
    color: $color-text-secondary;
    a {
        color: $color-text-secondary;
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }
}
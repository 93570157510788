.container {
  padding: $whitepane-padding;
  background: white;
  width: auto;
  margin: 0 auto;
  @media screen and (min-width: $breakpoint-lg) {
    position: relative;
  }
}


//fonts
@import url("https://fonts.googleapis.com/css?family=Dosis:400,600,700");

//todo: paths aanpassen
@font-face {
    font-family: 'Bariol';
    src: url('/fonts/Bariol_Regular.otf'),
    url('/fonts/bariol_regular-webfont.ttf'),
    url('/fonts/bariol_regular-webfont.woff');
    font-weight: normal;
}

@font-face {
    font-family: 'Bariol';
    src: url('/fonts/Bariol_Bold.otf'),
    url('/fonts/bariol_bold-webfont.ttf'),
    url('/fonts/bariol_bold-webfont.ttf');
    font-weight: bold;
}

$font-base: 'Bariol', Helvetica, Arial, sans-serif;
$font-heading: 'Dosis', sans-serif;

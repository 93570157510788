.makelaar-link {
    display: block;
    text-align: center;
    margin: $whitepane-padding;
    font-weight: bold;
    color: white;
    text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
    &:hover {
        color: darken(white, 5%);
    }
    @media screen and (min-width: $breakpoint-lg) {
        margin: $whitepane-padding 0;
        font-size: 22px;
        text-align: right;
        position: absolute;
        right: 7.5vw;
        bottom: 0;
    }
}

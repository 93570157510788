.realtor {
    width: 180px;
    margin: 40px 0;
    @media screen and (min-width: 440px) {
        float: left;
        margin: 40px auto;
        &:nth-child(even) {
            padding-left: calc(100vw - 420px);
        }
        &:nth-child(odd){
            clear: left;
        }
    }
    @media screen and (min-width: 660px) {
        margin: 30px 0;
        &:nth-child(3n-2) {
            padding-left: 0;
            clear: left;
        }
        &:nth-child(3n), &:nth-child(3n-1){
            padding-left: calc((100vw - 600px)/2);
            clear: none;
        }
    }
    @media screen and (min-width: $breakpoint-lg) {
        &:nth-child(3n), &:nth-child(3n-1){
            padding-left:33px;
        }
    };
    &_logo {
        max-width: 180px;
        height: 64.5px;
        margin-bottom: 10px;
        img {
            width: 100%;
        }
    }
    &_name {
        font-weight: bold;
        font-size: 18px;
    }
    &_adress {
        font-size: 14px;
        margin: 8px 0;
        color: $color-text-secondary;
    }
    &_url {
        font-family: $font-heading;
        font-weight: bold;
        text-transform: uppercase;
        a {
            font-size: 15px;
            color: $color-text-primary;
            text-decoration: none;
            border-bottom: 2px solid $color-text-primary;
            &:hover {
                color: lighten($color-text-primary, 50%);
                border-bottom: 2px solid lighten($color-text-primary, 50%);
            }
        }
    }

}
@import "realtor";

.results {
    padding-bottom: 20px;
    h2 {
        font-family: $font-heading;
        font-weight: 600;
        font-size: 20px;
        span {
            padding: 5px;
            background-color: $color-marking;
            line-height: 200%;
        }
        @media screen and (min-width: $breakpoint-md) {
            font-size: 28px;
        }
    }
    &_button-container {
        text-align: center;
    }
    @media screen and (min-width: $breakpoint-lg) {
        &_button-container {
            &.no-results {
                position: relative;
                right: auto;
                top: auto;
            }
        }
    }
}

.search-form {
    font-size: 18px;
    select {
        -ms-appearance: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        width: 100%;
        height: 60px;
        padding-left: 25px;
        border: 2px solid $color-border;
        border-radius: 0;
        background: $color-dropdown url("/img/caret_down.png") no-repeat calc(100% - 20px);
    }
    &_description {
        margin-bottom: 30px;
        font-size: 22px;
    }
    &_searchinput {
        margin: 33px 0;
        padding: 5px;
        width: 100%;
        height: 32px;
        border: none;
        border-bottom: 2px solid $color-border;
    }
    &_button-container {
        text-align: center;
        margin-top: 50px;
    }
    @media screen and (min-width: $breakpoint-lg) {
        padding-bottom: 0;
        margin: $whitepane-padding;
    }
    #name {
        display: none;
    }
    .awesomplete {
        display: block;
    }
}

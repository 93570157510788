.wrapper {
  flex: 1 0 auto;
  position: relative;
  background: white;
  background-image: $image;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: bottom;
  @media screen and (min-width: $breakpoint-lg) {
    padding: 15vh 0 50px;
    main {
      margin-left: auto;
      width: 666px;
      margin-right: $outer-margin-small;
      padding: 0;
      margin-bottom: 15vh;
    }

  }

  @media screen and (min-width: 1365px) {
    main {
      margin-right: $outer-margin;
    }
  }
}




body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    margin: 0;
    font: 18px $font-base;
    color: $color-text-primary;
    background-color: #EEE;
}

.clearfix {
    overflow: auto;
    &:after {
        content: "";
        clear: both;
        display: table;
    }
}

//colours
$color-yellow: #F7DB61;
$color-light-grey: #D8D8D8;
$color-lighter-grey: #F1F0ED;
$color-text-primary: black;
$color-text-secondary: #666666;


$color-border: $color-light-grey;
$color-dropdown: $color-lighter-grey;
$color-marking: $color-yellow
button {
    min-width: 160px;
    height: 3em;
    margin-left: auto;
    margin-right: auto;
    padding: 0 50px;
    border-radius: 50px;
    border: 2px solid black;
    background-color: white;
    font-family: $font-heading;
    font-weight: 800;
    font-size: 18px;
    text-transform: uppercase;
    cursor: pointer;
    &:hover {
        background-color: $color-lighter-grey;
    }
    &:active {
        background-color: $color-light-grey;
    }
    @media screen and (min-width: $breakpoint-md) {
        min-width: 290px;
        font-size: 24px;
    }

}

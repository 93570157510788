.footer-provinces {
    padding: 26px 18px 3px;
    background: white;
    @media screen and (min-width: $breakpoint-md) {
        padding: 26px 18px 23px;
        display: flex;
        justify-content: space-between;
    }
    &_province {
        margin-bottom: 20px;
        @media screen and (min-width: 450px) {
            width: 50%;
            float: left;
        }
        @media screen and (min-width: $breakpoint-md) {
            margin-bottom: 0;
            width: auto;
        }
        a {
            display: block;
            margin-bottom: 3px;
            font-size: 15px;
            color: $color-text-primary;
            text-decoration: none;
            &:hover {
                color: lighten($color-text-primary, 50%);
            }
        }
    }

    h3 {
        margin: 0 0 15px;
        font-family: $font-heading;
        font-weight: 600;
        font-size: 18px;
        background: none;
    }
    @media screen and (min-width: $breakpoint-lg) {
        padding-left: $outer-margin-small;
        padding-right: $outer-margin-small;
    }
    @media screen and (min-width: 1365px) {
        padding-right: $outer-margin;
    }
}

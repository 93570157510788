header {
    padding: $whitepane-padding;

    h1 {
        margin: 0;
        font-family: $font-heading;
        font-weight: normal;
        font-size: 37px;
        @media screen and (min-width: 1365px) {
            font-size: 74px;
        }
        span {
            float: left;
            line-height: .9;
            padding: 5px;
            margin-bottom: 10px;
            background-color: $color-marking;
            @media screen and (min-width: $breakpoint-lg) {
                clear: left;
            }
        }
    }
    .header-description {
        font-size: 22px;
        @media screen and (min-width: 1365px) {
            font-size: 26px;
        }
        span {
            float: left;
            line-height: .9;
            padding: 5px;
            margin-bottom: 5px;
            background-color: white;
            @media screen and (min-width: $breakpoint-lg) {
                clear: left;
            }
        }
    }
    @media screen and (min-width: $breakpoint-lg) {
        background: none;
        position: fixed;
        top: 50%;
        transform: translateY(-50%);
        left: $outer-margin-small;
        margin-top: -90px;
        padding: 0;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }
    @media screen and (min-width: 1365px) {
        main {
            margin-right: $outer-margin;
        }
    }
}
